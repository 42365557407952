import React from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'gatsby'
import Layout from '../components/Layout'

export default function NotFound() {
    return (
        <Layout>
            <div className={`container`} style={{ textAlign: 'center', paddingBottom: '50px' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Siden finnes ikke - Bitcoin Cash Norge</title>
                </Helmet>
                <h1>Denne siden finnes ikke!</h1>
                <Link to="/" style={{ fontSize: '1.5rem' }}>Gå til forsiden</Link>
            </div>
        </Layout>
    )
}
